<template>
  <v-app :style="{background: '#F5F5F5'}">

    <HeaderBar v-if="showHeader" displaySearchOnRoutes="Customers, SkuIndex, NewInputOrder" />

    <!-- Sizes your content based upon application components -->
    <v-main>
      <Welcome v-if="userState=='logged_out'" />
      <Live v-if="live" />
    </v-main>

    <v-snackbar bottom right :value="updateExists" :timeout="-1" color="primary">
      {{$t('updateAvailable')}}
      <v-btn text @click="refreshApp">
        {{$t('update')}}
      </v-btn>
    </v-snackbar>

    <AlertWrapper />
    <FooterBar v-if="live" />

  </v-app>
</template>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>


<script>
  import pusher from '@/mixins/pusher'
  import updatePwa from '@/mixins/updatePwa'

  import HeaderBar from '@/components/HeaderBar';
  import FooterBar from '@/components/FooterBar';
  import Welcome from '@/views/login/Welcome';
  import Live from '@/views/login/Live'

  import '@/filters.js';
  import {customerAppTheme} from "./enums/customer-theme";
  import lookups from "@/mixins/lookups";
  import prodDebug from "@/mixins/prodDebug";
  import {findAllCustomersUpdatedMismatch} from "@/helpers/utils";
  import AlertWrapper from "@/components/AlertWrapper";
  import './inputs-style.scss';
  import {getDeploymentRelease} from "@/mixins/errorLogging";

  export default {
    name: 'App',

    components: {
      HeaderBar,
      FooterBar,
      Welcome,
      Live,
      AlertWrapper
    },

    data: () => ({}),
    created () {
      window.document.title = process.env.VUE_APP_PARTNER_NAME
      // check sbg url
      if (window.location.origin.indexOf('sbg.') > -1) {
        this.$store.commit('setCustomerAppTheme', customerAppTheme.SBG);
      } else {
        this.$store.commit('setCustomerAppTheme', null);
      }
    },

    computed: {
      Apikey() {return this.$store.getters.apiKey; },
      userState() {
        let s = 'logged_out';
        if (this.$store.getters.authenticated)  { s = 'live'; }
        return s;
      },
      live() { return (this.userState=='live'); },
      customersUuidAndUpdatedAt() {
        return this.$store.getters.customersUuidAndUpdatedAt
      },

      isOnline() { return this.$store.getters.networkOnline; },

      showHeader() { return this.live },
    },

    methods: {
      updateConnectionStatus() {
        this.$store.commit('networkOnline', navigator.onLine);
        },
    },
    mounted() {
      this.$store.dispatch('updateGeolocation');
      this.$store.dispatch('clearAlerts')
      window.addEventListener('online', this.updateConnectionStatus);
      window.addEventListener('offline', this.updateConnectionStatus);

      if (this.isOnline) {
        if (this.Apikey && Object.keys(this.customersUuidAndUpdatedAt).length > 0) {
          this.remoteLookup('customersUpdated', { uuids: Object.keys(this.customersUuidAndUpdatedAt) }).then( serverCustomersUpdated => {
          const customersUpdatedMismatch = findAllCustomersUpdatedMismatch(serverCustomersUpdated, this.customersUuidAndUpdatedAt)
          if (customersUpdatedMismatch.length > 0) {
            this.$store.commit('retrieveDetailForCustomers', customersUpdatedMismatch);
          }
          }).catch(e => {
            console.log('customersUpdated.error', e)
          });
        }       
      }
      this.updateConnectionStatus(navigator.onLine);

      if (this.production) {
        getDeploymentRelease().then(data => {
          if (!!data.commit && !!data.commit) {
            this.$store.commit('updateDeploymentRelease', data);
          } else {
            this.$store.commit('updateDeploymentRelease', null);
          }
        });
      }
    },

    mixins: [updatePwa, pusher, lookups, prodDebug],
  };
</script>


<i18n>
{
  "en": {
    "update": "Update",
    "updateAvailable": "An update is available"
  },
  "es": {
    "update": "Actualizar",
    "updateAvailable": "Hay una actualización disponible"
  },
  "sw": {
    "update": "Sasisha",
    "updateAvailable": "Sasisho linapatikana"
  }
}
</i18n>
