<template>
  <div>
    <PageHeader
      title="Order Inputs"
      icon="mdi-flower-outline"
    />
    <v-data-table
      :headers="headers"
      :items="inputOrders"
    ></v-data-table>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
export default {
  components: {
    PageHeader
  },

  data () {
    return {
      headers: [
        { text: 'Farmer', value: 'customerName' },
        { text: 'Item', value: 'description' },
        { text: 'Quantity', value: 'quantity' },
        { text: 'Cost', value: 'value' },
      ]
    }
  },
  computed: {
    inputOrders() { return this.$store.getters.orders }
  }

}
</script>

<style>

</style>
